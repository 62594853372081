import React from 'react';
import settings from '../../../settings';

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import SponsorsList from '../../components/sponsors/sponsorslist';

const sponsors = [
  {
    id: 1,
    image: `${settings.IMAGES_BASE_URL}/images/motorrad/sponsors/continental-tires-sponsor-box.jpg`,
    imageAlt: 'Motorrad Continental Tires',
    title: 'Continental Tires',
    text:
     "A trusted supplier since 1999 (our inaugural year, each of the 2,500 sets of tires we've used since then has enabled us to push our BMWs to the max with confidence.",
     url: "https://www.continentaltire.com/"
  },
  {
    id: 2,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/bmw-clubCar-box.jpg`,
    imageAlt: 'Motorrad Club Car',
    title: 'Club Car',
    text:
      'The best way to get around our facility, club car makes gas or electric-powered carts that fit many lifestyles.',
      url: 'https://www.clubcar.com/us/en/home.html'
  },
  {
    id: 3,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/bmw-cca-box.jpg`,
    imageAlt: 'Motorrad BMW Car Club of America',
    title: 'BMW Car Club of America',
    text:
      'If you love all things BMW, this is the club for you. Members receive a 15% discount on all classes.',
      url: 'https://www.bmwcca.org/'
  },
  {
    id: 4,
    image: `${settings.IMAGES_BASE_URL}/images/mini/sponsors/mini-thermal-club-sponsor-box.jpg`,
    imageAlt: 'Motorrad Thermal Club',
    title: 'The Thermal Club',
    text:
      'Home to the Performance Center West, The Thermal Club boasts world-class facilities and luxurious accommodations, all surrounded by the thrill of motorsport.',
      url: 'http://thethermalclub.com/'
  },
  {
    id: 5,
    image: `${settings.IMAGES_BASE_URL}/images/sponsors/national-safety-council-box.jpg`,
    imageAlt: 'Motorrad National Safety Council',
    title: 'National Safety Council',
    text:
      'A key component in keeping both people and vehicles safe, the NSC has been a sponsor of the Performance Center since its inception.',
      url: 'https://www.nsc.org/'
  },
  {
    id: 6,
    image: `${settings.IMAGES_BASE_URL}/images/motorrad/sponsors/msf-rider-course-sponsor-box.jpg`,
    imageAlt: 'Motorrad Motorcycle Safety Foundation',
    title: 'Motorcycle Safety Foundation',
    text:
      'New riders learn to get started the right way with MSF courses nationwide.',
      url: 'https://www.msf-usa.org/'
  },
  {
    id: 7,
    image: `${settings.IMAGES_BASE_URL}/images/motorrad/sponsors/moto-cc-partners.jpg`,
    imageAlt: 'Motorrad Rewards At Full Throttle',
    title: 'Rewards At Full Throttle',
    text:
      'Accelerate your rewards with the BMW Motorrad Card – exclusively for BMW Motorrad riders. Redeem for unlimited cash back toward your BMW Motorrad Financial Services monthly payment or travel. Plus, you’ll save when you book a class with us over the phone.',
      url: 'https://www.mybmwmotorradcard.com/41627'
  }
];


const PartnersPage = () => {
  return (
    <Layout>
      <SEO title="BMW U.S. Rider Academy | Motorrad Partners" description="From the tires we trust to the Motorcycle Safety Foundation, our partners keep us moving safely into a place where adrenaline comes standard." />
      <div className="sponsors-page">
        <section className="hero__header">
          <div className="container">
            <div className="hero__header__content">
              <h1>Partners</h1>
            </div>
          </div>
        </section>
        <section className="sponsors">
          <div className="container">
            <SponsorsList sponsors={sponsors} />
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default PartnersPage;
